import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD,PATH_BUSINESS_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isVendorAuthenticated,isBusinessAuthenticated } = useAuth();
console.log("is auth guest gaurd==>",isBusinessAuthenticated,isVendorAuthenticated);
  if (isVendorAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.general.ecommerce} />;
  }
  if(isBusinessAuthenticated){
    return <Navigate to={PATH_BUSINESS_DASHBOARD.general.ecommerce} />;
  }

  return <>{children}</>;
}
