import axios from 'axios';
import { JWTconfig } from '../config';
// ----------------------------------------------------------------------

const axiosInstance = axios.create();
// axios.defaults.headers.common["token"] =process.env.REACT_APP_SITE_TOKEN;
axiosInstance.defaults.baseURL = JWTconfig.apiURL;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
