import { sum, map, filter, uniqBy, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import axios from 'axios';
import { API_V1 } from '../../endPoints';


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  vendorList: [],
  vendor: null,  
};

const slice = createSlice({
  name: 'gstinlist',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET GSTIN LIST
    getGstinListSuccess(state, action) {
      state.isLoading = false;
      state.vendorList = action.payload;
    },

    addSelectedGstin(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

   
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getGstinListSuccess,
  addSelectedGstin
} = slice.actions;

// ----------------------------------------------------------------------

export function getGstinList(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log("get GSTIN List")
      // const response = await axios.post(`${API_V1.businessSearchGstin}`,body);
      console.log("set GSTIN list response==>",body);
      dispatch(slice.actions.getGstinListSuccess(body.adadr));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// export function getProduct(name) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`${API_V1.vendorProducts}/${name}`);
//       dispatch(slice.actions.getProductSuccess(response.data));
//     } catch (error) {
//       console.error(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }


// //----------------------------------------------------------------

// export function getMasterProducts() {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       console.log("get profucts")
//       const response = await axios.get(`${API_V1.vendorMasterProducts}?page=1`);
//       console.log("get products response==>",response.data);
//       dispatch(slice.actions.getProductsSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }