import {JWTconfig} from './config'

export  const API_V1 = {
    vendorLogin: `${JWTconfig.apiURL}vendor/login`,
    vendorRegister: `${JWTconfig.apiURL}vendor/register`,
    vendorProfile:`${JWTconfig.apiURL}vendor/profile`,

    vendorProducts:`${JWTconfig.apiURL}vendor/products`,
    vendorCategories:`${JWTconfig.apiURL}vendor/categories`,
    vendorMasterProducts:`${JWTconfig.apiURL}vendor/master-products/search`,
    vendorMasterCategories:`${JWTconfig.apiURL}vendor/master-categories`,

    vendorAddProducts:`${JWTconfig.apiURL}vendor/product`,
    vendorUpdateProducts:`${JWTconfig.apiURL}vendor/update`,

    vendorOrders:`${JWTconfig.apiURL}vendor/orders`,
    vendorOrderDetails:`${JWTconfig.apiURL}vendor/order`,

    vendorCreateProduct:`${JWTconfig.apiURL}vendor/product`,



    businessCheckLogin: `${JWTconfig.apiURL}business/check-login`,
    businessLoginWithOTP: `${JWTconfig.apiURL}business/login`,
     
    businessSearchGstin: `${JWTconfig.apiURL}business/search-gstin`,
    businessRegisterOTP: `${JWTconfig.apiURL}business/check-business-mobile`,
    
    businessCreateVendor: `${JWTconfig.apiURL}business`,
    // businessProfile:`${JWTconfig.apiURL}business/profile`,




}
