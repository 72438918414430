import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import { API_V1 } from '../../../endPoints';

// ----------------------------------------------------------------------

export default function LoginFormBusiness() {
  const { businessLogin } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const [isSendOTP, setIsSendOTP] = useState(false);
  
  async function  sendOtp(){    
    setIsSendOTP(true);
    try {
      console.log("send otp==>", {mobile:formik.values.mobile})
      const response = await axios.post(`${API_V1.businessCheckLogin}`,
      {mobile:formik.values.mobile}
      // ,
      // {transformRequest: (data, headers) => {
      //   delete headers.common.Authorization;
      //   console.log("string==>",JSON.stringify(data))
      //   return {mobile:data.mobile}
      //   }
      // }    
      );
      
      setIsSendOTP(false);
      enqueueSnackbar(response.data.msg, { variant: 'success' });
    
    } catch (error) {
      console.error(error);
      setIsSendOTP(false);
      enqueueSnackbar('OTP send error pls try again later..!', { variant: 'error' });

    }
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const LoginSchema = Yup.object().shape({
    // mobile:Yup.string().matches(phoneRegExp, 'Phone number is not valid')
    mobile:Yup.string()
              .required()
              .matches(/^[0-9]+$/, "Must be only digits")
              .min(10, 'Mobile no. Must be exactly 10 digits')
              .max(10, 'Mobile no. Must be exactly 10 digits'),
    otp:Yup.string()
              .required()
              .matches(/^[0-9]+$/, "Must be only digits")
              .min(6, 'OTP no. Must be exactly 6 digits')
              .max(6, 'OTP no. Must be exactly 6 digits')
    // mobile: Yup.number().min(9,'Mobile number must contain 10 characters').max(10,'Mobile number must contain 10 characters').required('Mobile is required'),
  });

  const formik = useFormik({
    initialValues: {
      mobile: '',
      otp: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {

        console.log("inside business login ",values);
        await businessLogin(values.mobile, values.otp);
        enqueueSnackbar('Login success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} style={{margin: '40px 0 40px 0'}}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
            <Stack direction={{ xs: 'row', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="Mobile No."
                type="number"
                label="Registered Mobile no."
                {...getFieldProps('mobile')}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
              <LoadingButton  size="small" variant="contained" disabled={touched.mobile && errors.mobile} onClick={sendOtp} loading={isSendOTP}>
                    Get OTP
              </LoadingButton>
            </Stack>

            <TextField
                fullWidth
                autoComplete="otp"
                type="number"
                label="OTP"
                {...getFieldProps('otp')}
                error={Boolean(touched.otp && errors.otp)}
                helperText={touched.otp && errors.otp}
              />  

        </Stack>                  
        <LoadingButton fullWidth size="large" type="submit" variant="contained"  loading={isSubmitting}>
            Business Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
