import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from 'axios';
import { isValidToken, setBusinessSession, setVendorSession } from '../utils/jwt';
import { API_V1 } from '../endPoints';
import { JWTconfig } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  isVendorAuthenticated: false,
  isBusinessAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isVendorAuthenticated,isBusinessAuthenticated, user } = action.payload;
    return {
      ...state,
      isVendorAuthenticated,
      isBusinessAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isVendorAuthenticated: true,
      user
    };
  },
  BUSINESSLOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isBusinessAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isVendorAuthenticated: false,
    isBusinessAuthenticated: false,      
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isVendorAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  businessLogin:()=> Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  // console.log("auth provider",children);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const gbVendorToken = window.localStorage.getItem('gbVendorToken');
        const gbBusinessToken = window.localStorage.getItem('gbBusinessToken');


        if (gbVendorToken && isValidToken(gbVendorToken)) {
            setVendorSession(gbVendorToken);
            console.log("is autenticated inside jwt context auth",gbVendorToken);
            const response = await axios.get(`${API_V1.vendorProfile}`);
            const  user  = response.data;
            console.log("user details==>",user)
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isVendorAuthenticated: true,
                isBusinessAuthenticated: false,
                user
              }
            });
        }
        else if (gbBusinessToken && isValidToken(gbBusinessToken)) {
            setBusinessSession(gbBusinessToken);
            console.log("is autenticated inside jwt context auth",gbBusinessToken);
            // const response = await axios.get(`${API_V1.vendorProfile}`);
            // const  user  = response.data;
             const  user  = {vendor_name: "businesss"} 
            console.log("gbBusiness user details==>",user)
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isVendorAuthenticated: false,
                isBusinessAuthenticated: true,
                user
              }
            });
        }        
        else {
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isVendorAuthenticated: false,                  
                  isBusinessAuthenticated: false,
                  user: null
                }
              });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isVendorAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    console.log("auth provider",API_V1.vendorLogin,{
      "user_name":email,
      "password":password
    });

    // fetch(`${JWTconfig.apiURL}vendor/login`,
    // {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     "user_name":email,
    //     "password":password
    //       }),
    //   headers:{
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
  
    const response = await axios.post(`${API_V1.vendorLogin}`, {
      "user_name":email,
      "password":password
    }
    
    );
    const gbVendorToken = response.data.token;
    await setVendorSession(gbVendorToken);
    console.log('login-response',response.data)

    const usrresponse = await axios.get(`${API_V1.vendorProfile}`);
    const user = usrresponse.data
    console.log('usr-response',usrresponse.data)
 
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const businessLogin = async (mobile, otp) => {
    console.log("auth provider",API_V1.businessLoginWithOTP,{
      "mobile":mobile,
      "otp":otp
    });

    const response = await axios.post(`${API_V1.businessLoginWithOTP}`, {
                            "mobile":mobile,
                            "otp":otp
                          }  
                      );
    const gbBusinessToken = response.data.token;
    await setBusinessSession(gbBusinessToken);
    console.log('Business-login-response',response.data)

    const  user  = {vendor_name: "businesss"} 
    console.log("gbBusiness loginres details==>",user) 
    dispatch({
      type: 'BUSINESSLOGIN',
      payload: {
        user
      }
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { gbVendorToken, user } = response.data;

    window.localStorage.setItem('gbVendorToken', gbVendorToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };




  const logout = async () => {
    setVendorSession(null);
    setBusinessSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        businessLogin,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
