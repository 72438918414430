import { capitalCase } from 'change-case';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Tab, Tabs,Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Button } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';

import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';
import { LoginFormBusiness } from '../../components/authentication/Business-login';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();
  const SIMPLE_TAB = [
    { value: '1', icon: <PhoneIcon />, label: 'Vendor Login', disabled: false },
    { value: '2', icon: <FavoriteIcon />, label: 'Business Login', disabled: false }    
  ];
  const [value, setValue] = useState('1');
  const [valueScrollable, setValueScrollable] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title="Login | GrowBaskets">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Button variant="contained" component={RouterLink} to={PATH_AUTH.register}>
          Register your Business
        </Button>
        {/* <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
        </Link> */}
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to Growbaskets
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
            </Box>

            <Tooltip title={capitalCase(method)}>
              <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
            </Tooltip>
          </Stack>

          {method === 'firebase' && <AuthFirebaseSocials />}

          {/* <Alert severity="info" sx={{ mb: 3 }}>
            Use email : <strong>demo@growbaskets.com</strong> / password :<strong>&nbsp;demo1234</strong>
          </Alert> */}


          <TabContext value={value}>
                <TabList onChange={handleChange} style={{marginBottom:'10px'}}>
                  {SIMPLE_TAB.map((tab, index) => (
                    <Tab key={tab.value} label={tab.label} value={String(index + 1)} />
                  ))}
                </TabList>
                
                  {SIMPLE_TAB.map((panel, index) => (
                    <TabPanel key={panel.value} value={String(index + 1)}>
                      {panel.value==='1' ? (   
                        <>                    
                            <LoginForm />
                            {/* <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
                              Login
                            </Button> */}
                        </>
                      )
                    :
                          <LoginFormBusiness />
                    }
                   
                    </TabPanel>
                  ))}
                
          </TabContext>





          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Register your Business
              </Link>
            </Typography>
          </MHidden>




        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
